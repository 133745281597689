$purple: #452B55;
$dark-purple: #26172F;
$blue: #6284A1;
$dark-blue: #365977;
$light-blue: #E1EDF1;
$transparent-purple: rgba(69,43,85,.05);
$semi-trans-purple: rgba(69,43,85,.5);

* {
  margin: 0;
  padding: 0;
}
.App {
  margin: 0px;
  padding: 0px;
}
body {
  margin: 0px;
  padding: 0px;
  font-family: "Montserrat", sans-serif;
}
.leftNav {
  position: absolute;
  left: 0px;
  text-align: left;
  display: flex;
  align-items: center;
  .logo {
    margin: 5px;
  }
  a.resume {
    margin-left: 10px;
  }
}

h1 {
  margin: 0px;
  color: $dark-purple;
  font-size: 2.2em;
  font-weight: 200;
}
h2 {
  font-family: "yesteryear";
  font-size: 2.1em;
  color: $purple;
  text-transform: lowercase;
  font-weight: 200;
}
h3 {
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 20px;
  color: $purple;
}
h4 {
  color: $dark-purple;
  font-size: 1em;
  font-weight: 500;
  margin: 30px 0px 10px 0px;
}
ul li {
  list-style-type: none;
  font-size: 1em;
}

.italic {
  font-style: italic;
}
.aContainer {
  position: relative;
  margin: 25px 0px;
}
a {
  color: $purple;
  text-decoration: none;
  font-weight: 600;
  font-size: .875em;
  position: relative;
}
a.storyLink {
  font-size: 1em;
  color: $purple;
}
a.storyLink:hover {
  text-decoration: underline;
  color: $dark-purple;
}
a:hover {
  color: $dark-purple;
}
.app-header {
  text-align:  center;
  padding-top: 1px;
  h2 {
    margin: .5em;
    margin-top: 10px;
  }
}
a.regLink {
  font-weight: 500;
  font-size: inherit;
  color: $blue;
}
footer {
  background-color: $dark-purple;
  height: 100px;
  text-align: center;
  padding: 20px;
  h2 {
    color: $light-blue;
    font-size: 2em;
    font-weight: 300;
  }
  p {
    color: white;
    margin-top: 20px;
  }
}
.top {
  background-image: url('../img/bg.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: initial;
  margin: 0px;
  padding: 0px;
  padding-bottom: 10px;
  background-color: #F8F7F1;

  h1 {
    margin-bottom: 30px;
    font-family: yesteryear;
    font-size: 3.2rem;
    font-weight: 400;
    font-style: italic;
    position: relative;
    top: 175px;
    left: 0;
  }
  .top-diagonal {
    margin-left: calc(50% - 470px);
    margin-top: -140px;

    h1.titletwo {
      margin-left: 110px;
    }
    h1.titlethree {
      margin-left: 220px;
    }
  }
  h3.i_am {
    display: inline-block;
    top: 179px;
    position: relative;
    left: 20px;
    color: #6284A1;
    font-weight: 300;
    font-size: 1.1rem;
  }
  .socialchange {
    left: calc(50% - 120px);
    font-size: 1.3rem;
    color: #6284A1;
    font-weight: 300;
    text-align: left;
    top: -65px;
    position: relative;
    line-height: 2.2rem;
    overflow: hidden;
    display: inline-block;

    .semibold {
      color: $dark-purple;
    }
    .social {
      font-weight: 700;
      color: $purple;
      font-size: 1.4rem;
    }

    .socialchange_2 {
      margin-left: 40px;
    }
    .socialchange_3 {
      margin-left: 80px;
    }
  }
}

.bottomButtons {
  margin: auto;
  text-align: center;
  margin-top: 150px;  
}


.linkBox {
  width: 100px;
  display: inline-block;
  text-align: center;
  font-size: 1em;
  font-weight: 400;
  color: $purple;
  cursor: pointer;
  margin: 0px 10px 0px 10px;
  box-sizing: border-box;
  border-bottom: solid 2px transparent;
  padding: 0px;
  height: 34px;
  box-sizing: border-box;
}
.other {
  font-style: italic;
}
.linkBox:after {
  display:block;
  content: '';
  border-bottom: solid 2px $semi-trans-purple; 
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
  padding: 5px 0px 5px 0px;
  box-sizing: content-box;
  height: 2.5px;
}
.linkBox:hover:after { transform: scaleX(1); }

.selected {
  font-weight: 600;
  transform: scaleX(1);  
  pointer-events: none;
  cursor: auto;
  border-bottom: solid 2px $purple;
  box-sizing: border-box;
  height: 34px;
}

.semibold {
  font-weight: 500;
}

.center {
  margin: auto;
  text-align: center;
}

.left {
  text-align: left;
}


.bigSection {
  padding: 0px 0px 60px 0px;
  overflow: hidden;
  position: relative;

  p {
    max-width: 760px;
    width: 100%;
    position: relative;
    margin: auto;
    line-height: 1.6em;
    text-align: left;
    box-sizing: border-box;
    padding: 0px 0px 20px 0px;
  }
  p.center {
    text-align: center;
    margin: auto;
  }
  .diamondContainer {
    position: absolute;
    height: 1000px;
    width: 100vw;
    z-index: -1;
  }
  .backgroundDiamond {
    overflow: hidden;
    width: 350px;
    position: absolute;
    left: calc(50% + 280px);
    margin-top: -1456px;
    height: 500%;
    background-color: rgba(245, 244, 239, 0.25);
    transform: rotate(-45deg);
    z-index: -1;
  }
  .otherBackgroundDiamond {
    
    width: 350px;
    position: absolute;
    left: calc(50% + 72px);
    margin-top: -1049px;
    height: 500%;
    background-color: rgba(245, 244, 239, 0.25);
    transform: rotate(45deg);
    z-index: -1;
  }
  .one {
    position: relative;
    margin-top: -741px;
    height: 100%;
    margin-left: -1110px;
  }
  .two {
    position: relative;
    margin-top: -255px;
    height: 100%;
    margin-left: -1110px;
  }
  .three {
    position: relative;
    margin-top: -243px;
    height: 100%;
    margin-left: -1110px;
  }
}
/** Philosophy */

.philosophySection .section {
  .sectionTitle {
    margin: 40px 20px 25px 0px;
  }
  
}
.philosophySection .philosophyType {
  margin-top: 100px;
}
.sectionTitle {
  text-align: center;
  width: 400px;
  display: inline-block;
  margin: 25px 20px 25px 0px;
  h1 {
    font-size: 5em;
    font-weight: 900;
    color: $light-blue;
  }
  h2 {
    margin-top: -70px;
  }
}
  h3.pageTitle {
    background-color: $light-blue;
    color: $dark-purple;
    font-weight: 500;
    text-transform: lowercase;
    position: absolute;
    font-size: 1.3em;
    min-width: 200px;
    width: 25%;
    max-width: 500px;
    float: left;
    text-align: right;
    padding: 5px 10px 5px 10px;
    margin-top: 20px;
    border-radius: 2px;
  }

  nav.project-nav {
    position: relative;
    text-align: right;
    margin: 20px 15px 40px 0px;
    padding: 5px 0px 0px 0px;
    ul a {
      display: inline-block;
      padding: 5px;
      margin: 0px 5px 0px 5px;
      color: $blue;
      font-weight: 400;
      cursor: pointer;
      box-sizing: border-box;
    }
    
    ul a:not(.selected):after {
      display:block;
      content: '';
      border-bottom: solid 1.5px $blue;
      transform: scaleX(0);  
      transition: transform 250ms ease-in-out;
      padding: 5px 0px 5px 0px;
      box-sizing: border-box;
      width: 100%
    }
    ul a:hover:after { transform: scaleX(1); }
    ul a.selected {
      border-bottom: solid 1.5px $blue;
      box-sizing: border-box;
      transform: scaleX(1);
    }
  }
.selfiecontainer{
  position: absolute;
  height: 100%;
  background-color: $transparent-purple;
  width: 10%;
  left: 10px;
  margin-top: 70px;
  border-radius: 2px;
  width: 140px;

/** My Story */
  img.selfie {
    object-fit: cover;
    width: 120px;
    border-radius: 4px;
    right: 10px;
    position: absolute;
    top: 10px;
    border: solid 1px $transparent-purple;
  }
}

.myStoryContent {
  position: relative;
  margin: auto;
  margin-top: 90px;
  max-width: 760px;
  h3 {
    max-width: 650px;
    width: 100%;
    margin: auto;
    text-align: left;
    margin-bottom: 30px;
    line-height: 1.5em;
  }
  h4 {
    color: $blue;
    text-align: left;
    margin-top: 0px;
  }
  p {
    max-width: 760px;
  }
}


  /** Projects */

  .projectSection {
    position: relative;
    text-align: center;
    a {
      border: solid 1px $purple;
      border-radius: 2px;
      padding: 5px 10px;
    }
    a:hover {
      background-color: $transparent-purple;
    }
    .imageModal, .painting .imageModal {
      background-color: rgba(0,0,0,.8);
      position: fixed;
      top: 0px;
      width: 100vw;
      height: 100vh;
      cursor: pointer;
      z-index: 1;
  
      img {
        object-fit: cover;
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 100%;
        padding: 5px;
        margin: 0px;
      }

      h3.closeModal {
        color: white;
        float: right;
        margin-right: 20px;
        margin-top: 6px;
      }
    }
    img {
      position: relative;
      width: auto;
      max-width: 95%;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 40px;
      border: solid 1px rgba(69,43,85,.05);
      cursor: pointer;
    }

    .maine-map, .portland-map, .bgBox  {
      max-width: 600px;
      width: 49%;
      float: left;
      padding: 20px;
      box-sizing: border-box;
      border: 0px;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    .portland-map {
      float: right;
      padding-left: 10px;
      margin-top: 100px;
    }

    .poster {
      margin-top: 20px;
    }
    .card {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }

    .maine-map {
      padding-right: 10px;
    }

    .side-by-side, .side-by-side_left {
      overflow: auto;
      width: auto;
      max-width: 1200px;
      margin: auto;
      margin-bottom: 50px;
      text-align: left;

      .left-img {
        max-width: 150px;
        margin: 0px 40px;
        border: 0px;
      }
      .smaller {
        max-width: 75px;
        padding-top: 20px;
      }
      h4, p {
        float: left;
        margin-right: 40px;
        max-width: calc(100% - 400px);
      }
      h4 {
        font-weight: 600;
      }

      p.maine-map, p.portland-map {
        margin: 0px;
        margin-bottom: 80px;
      }
      .fb {
        float: right;
      }
      p.no-margin {
        margin: 0px;
      }

      .right {
        float: right;
      }

      .bgBox {
        background-color: rgba(255,255,255,.7);
        border-radius: 14px;
        border: solid 1px #f4ebe7;
        margin: 20px;
        max-width: calc(50% - 40px);
        padding: 30px;

        h4 {
          display: block;
          max-width: 100%;
          float: none;
          text-align: center;
        }
        p {
          max-width: 100%;
          margin: 10px 0px;
        }
      }
    }

    a.no-border {
      border: 0px;
    }

    .side-by-side_left {
      h4, p {
        margin-left: 40px;
      }
      .left-img {
        margin-top: -40px;
      }
    }

    .left-img {
      float: left;
    }

    .blur {
      filter: blur(5px);
      -webkit-filter: blur(5px);
    }
    .painting img {
      max-width: 900px;
      width: 100%;
    }
    .section {
      p {
        margin-bottom: 20px;
      }
    }
  }

.overflow ul {
  width: 100%;
  max-width: 760px;
  margin: auto;
  line-height: 1.6em;
  text-align: left;
  box-sizing: border-box;
  padding: 0px 0px 20px 60px;
  li {
    padding-bottom: 20px;
  }
  .strong {
    font-weight: 600;
    color: $blue;
  }
}
 

  .marginBottom {
    margin-bottom: 40px;
  }
  .outerVideo {
    margin-bottom: 20px;
    top: 20px;
    max-width: 900px;
    position: relative;
    margin: auto;
    text-align: center;
  .video {
    padding:56.25% 0 0 0;position:relative;
    margin-bottom: 50px;
    .innerVideo {
      position:absolute;top:0;left:0;width:100%;height:100%;
    }
  }
}
.wistia_responsive_wrapper {
  height: auto;
  left: 0;
  position: relative;
  top: 0px;
  padding-bottom: 40px;
  width: 100%;
}
.wistia_embed {
  height:100%;width:100%
}
.wistia_responsive_padding {
  max-width: 900px;
  margin: auto;
  padding:0 0 0 0;
  position:relative; 
  top: 20px;
  margin-bottom: 20px;
}

p.underline {
  padding-bottom: 40px;
  margin-bottom: 60px;
  border-bottom: solid 1px $transparent-purple;
  max-width: 100%;
  width: 900px;
}
p.small {
  font-size: .8em;
  margin-top: 30px;
}

.timeOff .timeOffExplanation {
  margin-bottom: 40px;
  max-width: 600px;
}

/* Media Queries */

@media screen and (max-width: 1066px) {
  .selfiecontainer {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  nav.project-nav {
    overflow: auto;
    margin-bottom: 0px;
    ul {
      max-width: 300px;
      float: right;
      padding-bottom: 20px;
    }
    ul li {
      display: block;
      padding: 10px 0px;
    }
    ul li:after {
      border-bottom: 0px;
      transform: none;  
      transition: none;
      padding:0px;
    }
    ul li:hover:after { transform: none; }
    ul li.selected {
      height: auto;
    }
  }
  .linkBox:after {
  display:block;
  content: '';
  border-bottom: solid 2px $semi-trans-purple; 
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
  padding: 5px 0px 5px 0px;
  box-sizing: content-box;
  height: 2.5px;
}
.linkBox:hover:after { transform: scaleX(1); }

.selected {
  font-weight: 600;
  transform: scaleX(1);  
  pointer-events: none;
  cursor: auto;
  border-bottom: solid 2px $purple;
  box-sizing: border-box;
  height: 34px;
}
  .sectionTitle {
    margin: 0px 20px 10px 0px;
  }
  .left {
    text-align: center;
  }
  .top {
    .top-diagonal {
      margin-left: calc(50% - 260px);
      margin-top: -150px;
      position: relative;
      top: 180px;
    }
    h1 {
      font-size: 1.7em;
      top: 0px;
    }
    .socialchange {
      font-size: .9em;
      margin-left: 60px;
      top: -15px;

      .social {
        font-size: .9rem;
      }
    }
  }
  .bottomButtons {
    margin-top: 75px;
  }


}

@media screen and (max-width: 520px) {
  .top {
    .top-diagonal {
      margin-left: 10px;
    
      h1 {
        margin-left: 0px;
        top: 0px;
      }
      h1.titletwo {
        margin-left: 0px;
      }
      h1.titlethree {
        margin-left: 0px;
      }
    }
    .linkBox {
      width: auto;
    }
    .socialchange {
      margin-left: 20px;
    }
    .change {
      font-size: .8em;
    }
    .social {
      font-size: .8em;
    }
  }
  .section p, .bigSection p {
    max-width: 100%;
    padding: 0px 15px 0px 15px;
  }
  h3.pageTitle {
    position: relative;
    min-width: 130px;
  }
}